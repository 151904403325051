<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-[#05023b] print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center justify-center w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img
                    class="w-30 mr-2"
                    style="opacity: 50%"
                    src="assets/images/logo_fase1.png"
                />
                <!-- <p class="font-bold text-lg">Auto Core</p> -->
            </div>
            <!-- Components -->
        </div>
        <!-- User -->
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <div
            class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-20 mb-4 opacity-75"
        >
            Versión: {{ version }}
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 relative">
    <!-- Header -->
    <div
        class="flex flex-0 items-center justify-between w-full h-12 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
    >
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>

        <div class="items-center px-2 gap-1 text-gray-700 hidden sm:flex">
            <ng-container
                *ngFor="let breadcrumb of breadcrumbs; let last = last"
            >
                <a
                    *ngIf="!last"
                    [routerLink]="breadcrumb.url"
                    class="cursor-pointer hover:underline"
                >
                    {{ breadcrumb.label }}
                </a>
                <span *ngIf="last" class="font-bold">{{
                    breadcrumb.label
                }}</span>

                <span *ngIf="!last" class="text-gray-400">/</span>
            </ng-container>
        </div>

        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <div *ngIf="user">
                <p class="text-gray-700">
                    Hola, <strong>{{ user.email }} </strong>
                </p>
            </div>

            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>

            <!-- Button -->
            <button
                *ngIf="user"
                mat-icon-button
                [matMenuTriggerFor]="options"
                [matTooltip]="user.email"
            >
                <mat-icon [svgIcon]="ICONS.USER_CIRCLE"></mat-icon>
            </button>

            <!-- Language menu -->
            <mat-menu [xPosition]="'before'" #options="matMenu">
                <ng-container *ngFor="let item of menu">
                    <button
                        mat-menu-item
                        class="group"
                        (click)="setActiveLang(item.id)"
                    >
                        <span class="flex items-center">
                            <mat-icon
                                [svgIcon]="item.icon"
                                class="text-primary"
                            ></mat-icon>

                            <span class="ml-3 group-hover:text-primary">{{
                                item.label
                            }}</span>
                        </span>
                    </button>
                </ng-container>
            </mat-menu>
        </div>

        <!--<div class="flex flex-col items-center w-full p-4" *ngIf="user">
            <div class="relative w-24 h-24">
                <img
                    class="w-full h-full rounded-full"
                    *ngIf="user?.profile_photo?.key"
                    [src]="hostFile + user.profile_photo.key"
                    alt="User avatar"
                />
                <mat-icon
                    class="icon-size-24"
                    *ngIf="!user?.profile_photo?.key"
                    [svgIcon]="'heroicons_solid:user-circle'"
                ></mat-icon>
            </div>
            <div class="flex items-center ml-auto">
                 <div class="w-full mt-3">
                    <button
                        [matMenuTriggerFor]="userActions"
                        class="flex items-center"
                    >
                        <img
                            class="w-full h-full rounded-full"
                            *ngIf="user?.profile_photo?.key"
                            [src]="hostFile + user.profile_photo.key"
                            alt="User avatar"
                        />
                        <mat-icon
                            class="icon-size-5"
                            *ngIf="!user?.profile_photo?.key"
                            [svgIcon]="'heroicons_solid:user-circle'"
                        ></mat-icon>

                        <span class="text-md font-medium">{{
                            user.email
                        }}</span>
                    </button>

                    <mat-menu [xPosition]="'before'" #userActions="matMenu">
                        <button mat-menu-item (click)="profile()">
                            <mat-icon
                                [svgIcon]="'heroicons_outline:lock-closed'"
                            ></mat-icon>
                            <span>Cambiar contraseña</span>
                        </button>
                    </mat-menu>
                </div> 
            </div>
        </div>-->

        <!-- <div class="flex items-center ml-auto">
            <user [showAvatar]="false"></user>
        </div> -->
    </div>
    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
        class="flex-0 items-center justify-end text-end w-full h-8 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden bottom-0 hidden xl:flex lg:flex md:flex"
    >
        <span class="font-medium text-secondary w-full"
            >Autocore &copy; {{ currentYear }} todos los derechos
            reservados</span
        >
        <!-- <div class="flex w-1/6">
            <img class="w-8" src="assets/images/logo_fase1.png" />
        </div> -->
    </div>
</div>
