import { Route, Routes } from '@angular/router';

import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutLinkComponent } from '@core/layout/layout-link.component';
import { PermissionModuleGuard } from '@core/auth/guards/permission.guard';
import { ROLES } from '@utils/constants';
import { PERMISSION_MODULE } from '@core/permissions/permission.type';

export const appRoutes: Route[] = [
    // Redirect empty path to '/main'
    { path: '', pathMatch: 'full', redirectTo: 'main' },

    {
        path: 'public',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'contact-us' },
            {
                path: 'contact-us',
                loadChildren: () =>
                    import('app/modules/public/contact-us/contact-us.routes'),
            },
        ],
    },

    // Auth routes for guests
    {
        path: 'account',
        canActivate: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'sign-in' },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
            },
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.routes'),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.routes'
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.routes'
                    ),
            },
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.routes'
                    ),
            },
        ],
    },

    // Admin routes
    {
        path: 'main',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },        
        children: [
            {
                path: '',
                redirectTo: 'transactions',
                pathMatch: 'full', 
            },
            {
                path: 'dashboard',
                data: {
                    code: PERMISSION_MODULE.indicator.code,
                },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import('app/modules/main/dashboard/dashboard.routes'),
            },
            {
                path: 'bookings',
                data: {
                    code: PERMISSION_MODULE.automated_bookings.code,
                },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import('app/modules/main/bookings/bookings.routes'),
            },
            {
                path: 'bookings-room',
                data: {
                    code: PERMISSION_MODULE.hyperpay_booking.code,
                },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/bookings-room/bookings-room.routes'
                    ),
            },
            {
                path: 'transactions',
                data: {
                    code: PERMISSION_MODULE.transaction.code,
                    secondaryCodes: [
                        PERMISSION_MODULE.hyperpay_booking.code,
                        PERMISSION_MODULE.automated_bookings.code,
                    ],
                },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import('app/modules/main/transactions/transactions.routes'),
            },
            {
                path: 'transactionsHyper',
                data: {
                    code: PERMISSION_MODULE.transaction.code,
                    secondaryCodes: [
                        PERMISSION_MODULE.hyperpay_booking.code,
                        PERMISSION_MODULE.automated_bookings.code,
                    ],
                },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import('app/modules/main/transactions/transactions.routes'),
            },
            {
                path: 'billing',
                data: {
                    code: PERMISSION_MODULE.billing.code,
                },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import('app/modules/main/billing/billing.routes'),
            },
            {
                path: 'balance',
                data: {
                    code: PERMISSION_MODULE.balance.code,
                    secondaryCodes: [
                        'soporte.qosco.wasi@autocore.pro',
                        'mauriciogianlucca@gmail.com',
                    ],
                },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import('app/modules/main/balance/balance.routes'),
            },
            {
                path: 'holdings-management',
                data: {
                    code: PERMISSION_MODULE.holding.code,
                },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/holding-management/holding-management.module'
                    ).then((m) => m.HoldingManagementModule),
            },
            {
                path: 'hotel-management',
                data: {
                    code: PERMISSION_MODULE.hotels.code,
                },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/hotel-management/hotel-management.module'
                    ).then((m) => m.HotelManagementModule),
            },
            {
                path: 'company-management',
                data: {
                    code: PERMISSION_MODULE.companies.code,
                },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/company-management/company-management.module'
                    ).then((m) => m.CompanyManagementModule),
            },
            {
                path: 'user-management',
                data: { code: PERMISSION_MODULE.users.code },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/user-management/user-management.routes'
                    ),
            },
            {
                path: 'apikey-management',
                data: { code: PERMISSION_MODULE.users.code },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/apikey-management/apikey-management.routes'
                    ),
            },
            {
                path: 'link',
                data: {
                    code: PERMISSION_MODULE.payment_link.code,
                },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import('app/modules/main/link-management/link.routes'),
            },
            {
                path: 'payment-agency',
                data: {
                    code: PERMISSION_MODULE.hyperpay_booking.code,
                },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/payment-agency/payment-agency.routes'
                    ),
            },
            {
                path: 'payments',
                data: {
                    code: PERMISSION_MODULE.hyperpay_booking.code,
                },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import('app/modules/main/payments/payments.routes'),
            },
            {
                path: 'agency-management',
                data: {
                    code: PERMISSION_MODULE.agencies.code,
                },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/agency-management/agency-management.module'
                    ).then((m) => m.AgencyManagementModule),
            },
            {
                path: 'dataphone',
                data: {
                    code: PERMISSION_MODULE.virtual_terminal.code,
                },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import('app/modules/main/dataphone/dataphone.routes'),
            },
            {
                path: 'profile',
                data: {
                    code: PERMISSION_MODULE.profile.code,
                },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import('app/modules/main/profile/profile.routes'),
            },
            {
                path: 'roles-management',
                data: { code: PERMISSION_MODULE.users.code },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/roles-management/roles-management.routes'
                    ),
            },
            {
                path: 'parameterization-management',
                // data: { code: PERMISSION_MODULE.users.code },
                // canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/parameterization/parameterization-management.routes'
                    ),
            },
            {
                path: 'criterias-management',
                data: { code: PERMISSION_MODULE.policy_criterias.code },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/criterias-management/criterias-management.routes'
                    ),
            },
            {
                path: 'policy-management',
                data: { code: PERMISSION_MODULE.booking_policies.code },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/policy-management/policy-management.routes'
                    ),
            },
            {
                path: 'sub-policy-management',
                data: { code: PERMISSION_MODULE.booking_policies.code },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/sub-policy-management/sub-policy-management.routes'
                    ),
            },
            {
                path: 'demo',
                // data: { code: PERMISSION_MODULE.users.code },
                // canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/register-demo/register-demo.routes'
                    ),
            },
            {
                path: 'otas-management',
                data: { code: PERMISSION_MODULE.otas.code },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/otas-management/otas-management.routes'
                    ),
            },
            {
                path: 'trm-management',
                data: { code: PERMISSION_MODULE.trm.code },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/trm-management/trm-management.routes'
                    ),
            },
            {
                path: 'plans-management',
                data: { code: PERMISSION_MODULE.policy_plans.code },
                canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import(
                        'app/modules/main/plans-management/plans-management.routes'
                    ),
            },

            {
                path: 'holdingDemo',
                // data: { code: PERMISSION_MODULE.users.code },
                // canActivate: [PermissionModuleGuard],
                loadChildren: () =>
                    import('app/modules/main/holding-demo/holding-demo.routes'),
            },
            {
                path: 'payment-alternative',
                // data: {
                //     layout: 'empty',
                // },
                loadChildren: () =>
                    import(
                        '../app/modules/main/payment-alternative/payment-alternative.routes'
                    ),
            },
        ],
    },

    // payment by link
    {
        path: 'payment',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('../app/modules/main/methods/methods.routes'),
            },
        ],
    },

    {
        path: 'demo',
        canActivate: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            { path: '', pathMatch: 'full', redirectTo: '' },
            {
                path: '',
                loadChildren: () =>
                    import(
                        'app/modules/auth/demo-password/demo-password-routes'
                    ),
            },
        ],
    },

    {
        path: 'nuvei',
        //canActivate: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            { path: '', pathMatch: 'full', redirectTo: '' },
            {
                path: '',
                loadChildren: () => import('app/modules/nuvei/nuvei.routes'),
            },
        ],
    },

    {
        path: 'virtual_cards',
        //canActivate: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            { path: '', pathMatch: 'full', redirectTo: '' },
            {
                path: '',
                loadChildren: () =>
                    import('app/modules/virtual-cards/virtual-cards.routes'),
            },
        ],
    },

    // payment by alternative
];
